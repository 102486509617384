import { Box, Flex, VStack } from "@chakra-ui/react";
import { Stake } from "./Stake";
import { useAccount, useBalance, useContractRead, useNetwork } from "wagmi";
import { getContractAddress } from "../../constants";
import { VeBalance } from "./veBalance";
import VotingEscrowAbi from '../../constants/abi/VotingEscrow.json';
import { fixed2Decimals } from "../../utils/helpers";
import Rewards from "./Rewards";

export interface LockedData{
  amount: number;
  unlockTime: number;
}
export function Tabs() {
  const {chain} = useNetwork();
  const {address} = useAccount();

  const tokenAddress = getContractAddress({chainId: chain?.id, contractName: "WEFI"});
  const veTokenAddress = getContractAddress({chainId: chain?.id, contractName: "veWEFI"});
  const rewardContractAddress = getContractAddress({chainId: chain?.id, contractName: 'StakingReward'});

  const {data: veBalance} = useBalance({
    address: address,
    token: veTokenAddress,
    watch: true
  });
 
  const {data: lockedRes } : {data: any[] | undefined} = useContractRead({
    address: veTokenAddress,
    abi: VotingEscrowAbi,
    functionName: 'locked',
    args: [address],
    watch: veBalance && Number(veBalance.value.toString()) > 0
  });

  const getLockedData = ()=> {
    const data : LockedData = {
      amount: 0,
      unlockTime: 0
    }
    if(lockedRes) {
      data.amount = Number(fixed2Decimals(lockedRes[0].toString(), 18));
      data.unlockTime = Number(lockedRes[1].toString());
    }
    return data;
  }
  const lockedData = getLockedData();

  const showStake = true; // lockedData.amount > 0 ? (lockedData.unlockTime >= (Date.now() / 1000)) : true;

  if (tokenAddress && veTokenAddress) {
    return <Flex
      direction={{sm: 'column', base: 'column', md: 'row'}}
      gap = '40px'
    >
      {(rewardContractAddress !== undefined || Number(veBalance ? veBalance?.value.toString() : "0") > 0) && <Flex
        w={{sm: '100%', base: '100%', md: '30%'}}
        direction='column'
        gap='40px'
      >
        <VeBalance
          veTokenAddress={veTokenAddress}
          veBalance={veBalance ? veBalance?.value.toString() : "0"}
          lockedData = {lockedData}
        />
        <Rewards
          rewardContractAddress={rewardContractAddress}
        />
      </Flex>}
     
      {showStake && <Box
        w={{sm: '100%', base: '100%', md: '50%'}}
        borderRadius='16px'
        border='1px solid'
        borderColor='gray.150'
        padding='20px'
      >
        <Stake
          tokenAddress = {tokenAddress}
          veTokenAddress = {veTokenAddress}
          lockedData={lockedData}
        />
      </Box>}
    </Flex>
  }
}