import BigNumber from "bignumber.js";
import { Address, encodeAbiParameters, encodeFunctionData } from "viem";
import moment from "moment";


export const shortAddress = (address: string | undefined) => {
  return address ? address.substring(0, 6) + "..." + address.substring(address.length - 4) : '';
}

export const formatVolume = (num: number):string => {
  if(num === 0) {
    return "0";
  } else if(num > 999 && num < 1000000){
    return (num/1000).toFixed(2) + 'K'; // convert to K for number from > 1000 < 1 million 
  } else if(num > 1000000){
    return (num/1000000).toFixed(2) + 'M'; // convert to M for number from > 1 million 
  } else {
    return num.toFixed(2); // if value < 1000, nothing to do
  }
}

export function getUSDPrice(
  oraclePrice: string | number,
  amount: string | number,
  tokenDecimals?: string | number,
  scaleDown: boolean = true
) {
  // oraclePrice = fixed2Decimals(oraclePrice, 8);
  if(tokenDecimals && scaleDown) {
      amount = fixed2Decimals(amount, tokenDecimals);
  }
  return  new BigNumber(oraclePrice).multipliedBy(new BigNumber(amount)).toFixed(2, 1);
}

export function calculateApy(ratePerBlock:string, blocksPerDay: number) {
  return (
    (Math.pow( Number(fixed2Decimals(ratePerBlock, 18)) * blocksPerDay + 1, 365) -1)
  ) * 100;
}

/*
  Big Number Scaling Up/down & Math Ops
*/
export function decimal2FixedZero(amount: string|number, decimals: string|number) {
  var tDec = new BigNumber("10")
      .exponentiatedBy(new BigNumber(decimals))
      .toFixed();
  return new BigNumber(amount).multipliedBy(tDec).toFixed(0);
}

export function decimal2Fixed(amount: string|number, decimals: string|number) {
  var tDec = new BigNumber("10")
      .exponentiatedBy(new BigNumber(decimals))
      .toFixed();
  return new BigNumber(amount).multipliedBy(tDec).toFixed();
}

export function fixed2Decimals(amount: string|number, decimals: string|number, precision?:number, round: BigNumber.RoundingMode = 0) {
  var tDec = new BigNumber("10")
      .exponentiatedBy(new BigNumber(decimals))
      .toFixed();
  let value = new BigNumber(amount).dividedBy(tDec);
  return precision === undefined ? value.toFixed() : value.toFixed(precision, round);
}

export function mul(amount1: string|number, amount2: string|number) {
  return new BigNumber(amount1).multipliedBy(new BigNumber(amount2)).toFixed();
}

export function sub(amount1: string|number, amount2: string|number) {
  return new BigNumber(amount1).minus(new BigNumber(amount2)).toFixed();
}

export function div(amount1: string|number, amount2: string|number) {
  return new BigNumber(amount1).dividedBy(new BigNumber(amount2)).toFixed();
}

export function division(amount1: string|number, amount2: string|number) {
  return new BigNumber(amount1).dividedBy(new BigNumber(amount2)).toFixed();
}

export function mulFixed(amount1: string|number, amount2: string|number) {
  return new BigNumber(amount1).multipliedBy(new BigNumber(amount2)).toFixed(0);
}

export function divFixed(amount1: string|number, amount2: string|number) {
  return new BigNumber(amount1).dividedBy(new BigNumber(amount2)).toFixed(0);
}

export function isGreaterThanOrEqualTo(amount1: string|number, amount2: string|number) {
  return new BigNumber(amount1).isGreaterThanOrEqualTo(new BigNumber(amount2));
}

export function isGreaterThan(amount1: string|number, amount2: string|number) {
  return new BigNumber(amount1).isGreaterThan(new BigNumber(amount2));
}

export function isEqual(amount1: string|number, amount2: string|number) {
  return new BigNumber(amount1).isEqualTo(new BigNumber(amount2));
}

export function isLessThanOrEqualTo(amount1: string|number, amount2: string|number) {
  return new BigNumber(amount1).isLessThanOrEqualTo(new BigNumber(amount2));
}

export function isLessThan(amount1: string|number, amount2: string|number) {
  return new BigNumber(amount1).isLessThan(new BigNumber(amount2));
}



export function isZero(amount: string|number) {
  return  new BigNumber(amount).isZero();
}

export function minus(amount1: string|number, amount2: string|number) {
  return new BigNumber(amount1).minus(new BigNumber(amount2)).toFixed();
}

export function minusFixed(amount1: string|number, amount2: string|number) {
  return new BigNumber(amount1).minus(new BigNumber(amount2)).toFixed(0);
}

export function addFixed(amount1: string|number, amount2: string|number) {
  return new BigNumber(amount1).plus(new BigNumber(amount2)).toFixed(0);
}

export const getReadableError = (error: string | undefined)=> {
  if (!error) return "";
  if(error.includes('0xc7ddad43')) {
    return  'High Volaitle Market. Protocol is unable to facilitate swap now.';
  } else if (error.includes('Contract Call:')) {
    return error.split("Contract Call:")[0];
  } else if(error.includes('User rejected the request.')) {
    return 'User rejected the request.'
  } else {
    return '';
  }
}

export const encodeDexData = async (chainId: number | undefined, dexId: string, fee: string, quoteJson:any, sender:string, recipient:string) => {
  let components = [{ type: 'uint8', name: 'dexId' }, { type: 'uint24', name: 'fee' }, { type: 'bytes', name: 'routerCallData' }];
  let routerCallData = '0x';
  if (dexId === "3") {
    routerCallData = await buildKyberSwapCalldata({
      routeSummary: quoteJson.routeSummary,
      sender,
      recipient,
      source: "wefi.xyz",
      // skipSimulateTx: false,
      slippageTolerance: 50,
      // deadline: moment().unix() + 1200
    });
  } else if(dexId === "5" || dexId === "7") {
    routerCallData = quoteJson.calldata;
  } else if(dexId === '8') {
    routerCallData = buildAkkaCalldata(quoteJson, recipient)
  }
  return encodeAbiParameters(
    [{ components, name: 'DexData', type: 'tuple' }],
    [{ dexId: dexId, fee: fee, routerCallData: routerCallData }]
  );
}

interface KyberSwapBuildCalldataBody {
  routeSummary: any;
  sender: Address | string;
  recipient: Address | string;
  source: string;
  slippageTolerance?: number;
  deadline?: number;
  skipSimulateTx?: boolean;
}

export async function buildKyberSwapCalldata(data: KyberSwapBuildCalldataBody) {
  const res:any = await fetch(`https://aggregator-api.kyberswap.com/linea/api/v1/route/build`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-client-id': 'wefi.xyz'
    },
    body: JSON.stringify(data) 
  });
  const json = await res.json();
  return json.data.data;
}

const akkaSwapAbi = [{
  name: 'multiPathSwap',
  type: 'function',
  stateMutability: 'payable',
  inputs: [
    { name: 'amountIn', type: 'uint256' },
    { name: 'amountOutMin', type: 'uint256' },
    { 
      name: 'data',
      type: 'tuple[]',
      components: [
        { name: 'srcAmount', type: 'uint256' },
        { name: 'dstMinAmount', type: 'uint256' },
        { name: 'isFromNative', type: 'uint256' },
        { name: 'isToNative', type: 'uint256' },
        {
          name: 'paths',
          type: 'tuple[]',
          components: [
            { name: 'srcToken', type: 'address' },
            { name: 'dstToken', type: 'address' },
            { name: 'pairAddr', type: 'address' },
            { name: 'fee', type: 'uint256' },
            { name: 'srcAmount', type: 'uint256' },
            { name: 'dstMinAmount', type: 'uint256' },
            { name: 'feeSrc', type: 'uint256' },
            { name: 'feeDst', type: 'uint256' },
            { name: 'liquidityType', type: 'uint256' }
          ]
        }
      ]
    },
    { name: 'to', type: 'address' },
    { name: 'fee', type: 'uint256' },
    { name: 'v', type: 'uint8' },
    { name: 'r', type: 'bytes32' },
    { name: 's', type: 'bytes32' }
  ],
  outputs: []
}]


export function buildAkkaCalldata(resJson: any, recipient: any) {
  const swapData = resJson.swapData;
  return encodeFunctionData({
    abi: akkaSwapAbi,
    functionName: 'multiPathSwap',
    args: [
      BigInt(resJson.inputAmount.value),                    // amountIn
      BigInt(swapData.amountOutMin),                // amountOutMin
      swapData.data,                                // SplitedPathDescription[]
      recipient,                                  // to address
      BigInt(swapData.akkaFee.fee),                // fee
      Number(swapData.akkaFee.v),                   // v
      swapData.akkaFee.r,                          // r
      swapData.akkaFee.s                           // s
    ]
  })
}

export function getFutureRoundedEpoch(startTime: number, months: number) {
  const weeks = moment().add(months, "months").diff(moment(), "weeks");
  const now = startTime === 0 ? Math.floor(Date.now() / 1000) : startTime; // Current epoch time in seconds
  const seconds = weeks * 7 * 24 * 60 * 60; // Seconds in 28 days
  const futureTime = now + seconds; // Epoch time after 28 days
  const roundedEpochTime = Math.round(futureTime / 604800) * 604800;
  return roundedEpochTime;
}
